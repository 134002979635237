

:root{
  --pink: rgba(255, 57, 170, 0.9);
  --lightBlue:#00AEEF;
  --darkAsh:#333333;
  --mainOrange: #F8951D;

}
.App{
  font-family: 'Noto Sans JP', sans-serif;
}

/* .owl-dots{
display:block;
  
} */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}


  
