.owl-theme .owl-dots .owl-dot span {
    background-color:transparent!important;
    border:1px solid white;
    }
    .owl-theme .owl-dots .owl-dot.active span {
      background-color:white!important;
      outline:0;
      }
      @media(max-width:576px){
        .owl-theme .owl-nav.disabled+.owl-dots{
          margin-top:35px;
            }
      }